<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">流程接口（包括部门接口）</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">一代质量大师戴明创建了“链模型”，即过程流，如图所示：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/33.png"
        class="SonImg"
      />
      <p class="SmallP">
        显然，链模型强调接口，输出端与下游接口，输入端与上游接口，本质是连接，这与互联网的本质是一致的。如：
      </p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">流程接口</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/34.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP" style="padding: 0">
        其可见，一个过程的输出将成为下一过程的输入，过程的输出决定管理的接口，此原理可以扩展到供应链：<span
          style="font-weight: bold"
          >供方——组织——顾客</span
        >。
      </p>
      <p class="SmallP">
        同时，过程是一个闭环，有起点和终点，闭环上的任何一个切入点都可追溯到起点或终点。如下图是三个过程联动和闭环的实例：
      </p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">过程齿轮图</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/35.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP">在戴明博士的链模型基础上，可以扩展为:</p>
      <div class="ImgP">
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/36.png"
          class="SonImgW"
        />
        <span class="SmallP" style="width: 250px; display: inline-block"
          >（a） 过程横向接口</span
        >
        <span class="SmallP" style="width: 250px; display: inline-block"
          >（b） 过程纵向接口</span
        >
      </div>
      <p class="SmallP">这样，就构网络，如同捕鱼网那样。</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>